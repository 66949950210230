//-------------------------------------------------------------
//  Application Custom Styles
//  Write any custom, non-component-based styles in this file.
//
//  All @imports should be in main.scss, not here.
//-------------------------------------------------------------

.visibility-hidden {
  visibility: hidden;
}

// Remove the bootstrap field glows
.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-clip: padding-box;
}

// Custom select based on http://primercss.io/
select.form-control {
  display: inline-block;
  max-width: 100%;
  height: 39px;
  // IE9 hacks to reduce padding
  padding-right: 24px;
  padding-right: 8px \9;
  background: #fff
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC')
    no-repeat right 8px center;
  // IE9 hacks to hide the background-image
  background-image: none \9;
  background-size: 8px 10px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  // Hides the default caret in IE11
  &::-ms-expand {
    opacity: 0;
  }

  &[multiple] {
    height: auto;
  }
}

.tokenfield {
  height: auto;
}

.tokenfield.focus {
  border-color: $input-border-focus;
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Tweaks for the sorting icons
.table-sortable th i {
  font-size: smaller;
  padding-left: 3px;
}

// Change sortable table headers to be blue
th a,
th a:hover,
th a:focus {
  color: $brand-blue-dark;
}

// Hide content but show if the table row is hovered
.table__show-on-hover {
  // uses opacity so table doesn't shift when showing/hiding
  opacity: 0;

  tr:hover &,
  &:focus {
    opacity: 1;
  }
}

.navbar-default .main-nav {
  @media (min-width: $screen-md) {
    font-size: 16px;
  }

  > li {
    > a {
      line-height: 20px;
      padding: 30px 13px;
      position: relative;

      .nav__inner-text {
        position: relative;
      }

      .nav__inner-text::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        border-top: 3px solid $brand-primary-light;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform .2s;
      }

      &:hover,
      &:focus {
        color: $brand-primary-light;

        .nav__inner-text::after {
          transform: scaleY(1);
        }
      }
    }

    &.active {
      > a {
        background: transparent;
        font-weight: 700;

        &:hover,
        &:focus {
          background: transparent;
        }

        .nav__inner-text::after {
          border-color: theme('colors.brand-secondary');
          transform: scaleY(1);
        }
      }
    }
  }
}

.nav-menu-button {
  padding: 23px;

  .nav-menu-button__icon-bar {
    @apply block bg-brand-primary rounded-full;
    width: 22px;
    height: 2px;
    margin-bottom: 4px;

    &:last-child {
      @apply mb-0;
    }
  }
}

.main-nav-link {
  @apply inline-block relative text-brand-primary;
  line-height: 20px;
  padding: 30px 13px;

  .main-nav-link__inner {
    @apply relative;
  }

  .main-nav-link__inner::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    border-top: 3px solid theme('colors.navy.light');
    transform: scaleY(0);
    transform-origin: top;
    transition: transform .2s;
  }

  &:hover,
  &:focus {
    @apply text-navy-light no-underline;

    .main-nav-link__inner::after {
      transform: scaleY(1);
    }
  }

  &.active {
    background: transparent;
    font-weight: 700;

    &:hover,
    &:focus {
      @apply bg-transparent text-brand-primary;
    }

    .main-nav-link__inner::after {
      border-color: theme('colors.brand-secondary');
      transform: scaleY(1);
    }
  }

  @media (max-width: theme('screens.sm-max.max')) {
    @apply block p-6;
  }
}

.secondary-nav-button {
  @apply inline-block relative text-brand-primary text-left ml-16;
  padding: 30px 0 30px 13px;
  line-height: 20px;
  font-weight: inherit;

  @media (max-width: theme('screens.sm-max.max')) {
    @apply block p-6 w-full ml-0;
  }
}

.secondary-nav-link {
  @apply text-brand-primary clear-both block p-6 whitespace-nowrap sm:py-1.5 px-8 leading-8;

  &:hover,
  &:focus {
    @apply no-underline;
  }

  @media (min-width: theme('screens.sm')) {
    @apply text-gray-500 text-base;
  }
}

.main-nav-right {
  @media (min-width: $screen-md) {
    font-size: 16px;
  }
}

.dropdown-settings {
  li {
    a {
      color: $neutral-800;
    }
    a:hover {
      background-color: $white;
      color: $brand-navy;
    }
  }
}

.nav-brand-logo {
  padding-left: 15px;
  height: 40px;
  margin-right: 40px;
}

.footer {
  height: 80px;
  position: absolute;
  bottom: 0;
  width: 100%;

  padding: 20px 0px;
  margin: 40px 0 0;
  border-top: 1px solid #e8e4e9;
  background-color: #ffffff;

  padding-top: 32px;

  .footer-right {
    float: right;
  }

  .footer-deliver {
    position: absolute;
    color: $brand-navy;
    font-size: 20px;
    font-weight: 600;
  }

  .footer-link {
    color: $brand-navy;
    font-weight: 600;
    height: 18px;
    line-height: 150%;
  }

  .phone-link {
    color: $neutral-800;
    height: 18px;
    line-height: 150%;
  }

  .phone-link:hover {
    text-decoration: none;
  }
}

.banner {
  margin-bottom: 20px;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 140px;
}

// Removes the border and boldens the text to differentiate, because
// panel-default headings now have no gray background
.panel > .panel-heading {
  border: none;
  font-weight: bold;
}

// Container to allow for responsive sizing
.maps-container {
  position: relative;
  padding-bottom: 56.25%; // magic number for aspect ration on the container
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.maps-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.panel-heading .text-muted {
  font-weight: normal;
}

// Make the link inside the nav-tabs look clickable (because it is)
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  cursor: pointer;
}

@media (max-width: $screen-xs-max) {
  .xs-full-width {
    margin: 0 -15px;
  }

  .xs-remove-top-margin {
    margin-top: -20px;
  }
}

.small-image {
  max-width: 120px;
}

.smaller-image {
  max-width: 100px;
}

.medium-image {
  max-width: 240px;
}

.medium-text {
  font-size: $font-size-h4;
  font-weight: 500;
}

.big-text {
  font-size: $font-size-h3;
  font-weight: 500;
}

.bigger-text {
  font-size: $font-size-h1;
  font-weight: 500;
}

.bold-text {
  font-weight: 600;
}

.gray-link {
  color: $gray-light;
}

.link-muted {
  color: $gray-light;
  font-weight: bold;
}

.link-muted:hover,
.link-muted:focus {
  color: $gray;
}

.link-blue {
  color: $brand-blue;
}

.link-blue:hover,
.link-blue:focus {
  color: $brand-blue;
  text-decoration: underline;
}

.navbar-color {
  background-color: white;
}

.navbar-pro {
  margin-bottom: 0;
}

.navbar-admin {
  .navbar-brand {
    height: 80px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .navbar-brand-logo {
    float: left;
    width: 45px;
    max-height: 30px;
    padding-right: 10px;
  }

  .navbar-brand-text {
    color: $gray-lighter;
    line-height: 30px;
    padding-bottom: 30px;
    padding-top: 30px;

    :focus {
      color: $gray-lighter;
    }
  }
}

.nav-bar-brand {
  padding: 20px 0px;

  > img {
    display: inline;
  }
}

.nav-bar-org-brand {
  align-items: center;
  display: flex;
  min-height: 80px;

  .nav-brand-logo {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: $screen-sm) {
    display: block;
  }
}

.nav-bar-org-brand-block-left {
  text-align: left;

  @media screen and (max-width: $screen-sm) {
    padding-top: 20px;
  }
}

.nav-bar-org-brand-block-center {
  text-align: center;

  @media screen and (max-width: $screen-sm) {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left;
  }
}

.nav-bar-org-brand-block-right {
  text-align: right;

  @media screen and (max-width: $screen-sm) {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left;
  }
}

.nav-bar-org-brand-settings {
  @media screen and (max-width: $screen-sm) {
    margin-top: -80px;
    padding: 0;
  }
}

.nav-org-brand-logo {
  display: inline-block;
  height: auto;
  max-height: 60px;
  max-width: 250px;
  width: auto;
}

.gray-link:hover,
.gray-link:focus {
  color: $gray;
  text-decoration: none;
}

.footer-phone {
  font-size: 16px;
  padding: 7px 0;
}

.footer-logo {
  max-height: 36px;
}

.h3-subtext {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-h3 * 0.75));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: floor(($font-size-h3 * 0.8));
  }
}

.text-danger .btn-default {
  color: $brand-danger;
  border-color: $brand-danger;
}

.text-danger .btn-default:hover {
  background-color: lighten($brand-danger, 50%);
}

.text-muted .btn-default {
  color: $gray-light;
  border-color: $gray-light;
}

.btn-link {
  color: $brand-navy;

  &:hover {
    color: $brand-navy-dark;
    text-decoration: underline;
  }
}

.text-danger .link,
.alert-danger a {
  color: $brand-danger;
  font-weight: bold;
}

.alert-info a {
  color: $brand-info;
  font-weight: bold;
}

.alert-warning a {
  color: $brand-warning;
  font-weight: bold;
}

.label .glyphicon {
  top: 2px;
}

.icon-vehicletypecar,
.icon-vehicletypepickuptruck,
.icon-vehicletypevan {
  padding-right: 0.4em;
}

.icon-3-hour,
.icon-direct {
  padding-right: 1.2em;
}

.card-icon {
  font-size: 1.5em;
  margin-right: 0.3em;
}

.visa ~ .icon-cc-visa,
.mastercard ~ .icon-cc-mastercard,
.discover ~ .icon-cc-discover,
.american_express ~ .icon-cc-amex {
  display: block !important;
  font-size: 1.5em;
  margin-right: 0.3em;
  color: $gray-light;
}

.indented {
  padding-left: 20px;
}

.indented-border {
  margin-left: 10px;
  padding-left: 20px;
  border-left: 1px solid $neutral-300;
}

.list-group-item .list-group-item-actions {
  overflow: hidden;
  padding-top: 10px;
}

.list-group-item-indented {
  padding-left: 35px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    background: $gray-lighter;
    border-right: 1px solid $list-group-border;
  }
}

.radio .disabled {
  color: $gray-lightest;
}

.disabled .help-block {
  color: $gray-lightest;
}

.label-edit {
  padding-left: 0.5em;
  font-weight: normal;
}
.thead-adjustments {
  background-color: $brand-light-gray;
}
.input-toggles {
  display: none;
}
#adjustment_notes_holder {
  display: none;
  color: rgb(116, 16, 16);
}
#adjustment_helper_button {
  border: none;
  &:focus {
    outline: none;
  }
}
.price_card_layout {
  padding: $panel-body-padding;
  border-radius: $panel-border-radius;
  border: 1px solid $panel-default-border;

  & dd,
  dt {
    display: inline;
  }
  & dl {
    display: flex;
    justify-content: space-between;
  }
}
.driver_orders_heading {
  font-weight: 500;
}

.driver_my_earnings_header {
  padding: 15px 0 35px 0;
  display: flex;
  flex-direction: row;
  & .vertical-break {
    margin: 5px 15px;
    border-left: 1px solid $gray-light;
    height: 40px;
  }
  & .driver_my_earnings_header_logo {
    margin: 10px 0;
    max-height: 30px;
  }
  & .driver_my_earnings_header_text {
    height: 30px;
    line-height: 30px;
    font-weight: 400;
  }
}

.driver_dashboard_container_container {
  flex-direction: column;
  @media screen and (min-width: $screen-md) {
    display: flex;
    flex-direction: row;
  }
  & .driver_dashboard_container {
    @media screen and (min-width: $screen-md) {
      flex: 2;
    }
    & .driver_dashboard_container_text {
      @media screen and (min-width: $screen-md) {
        width: 70%;
        padding: 20px 0;
      }
      @media screen and (max-width: $screen-md) {
        text-align: center;
      }
      & .driver_dashboard_header {
        font-weight: 400;
        @media screen and (min-width: $screen-md) {
          padding: 10px 0;
          font-size: 4em;
        }
      }
      & .driver_dashboard_subtext {
        font-weight: 400;
        color: $gray;
        @media screen and (min-width: $screen-md) {
          padding: 10px 0;
          line-height: 36px;
          font-size: 2em;
        }
        @media screen and (max-width: $screen-md) {
          margin: 0 10%;
        }
      }
    }
    & .mobile_link_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      @media screen and (min-width: $screen-md) {
        flex-direction: row;
        & img {
          height: 80px;
        }
      }
      @media screen and (orientation: landscape) {
        flex-direction: row;
        @media screen and (max-width: $screen-md) {
          margin: 0 12.5%;
        }
      }
      & .ios_mobile_link {
        & img {
          padding: 12px;
          padding-left: 0;
        }
      }
    }
  }
  .driver_resources_container {
    @media screen and (min-width: $screen-md) {
      flex: 1;
      padding: 10px 0;
      border-left: 1px solid $gray-lightest;
    }
    @media screen and (max-width: $screen-md) {
      border-top: 1px solid $gray-lightest;
    }

    & .driver_resources_header {
      font-weight: 400;
      @media screen and (min-width: $screen-md) {
        padding: 0px 10px 0px 25px;
      }
      @media screen and (max-width: $screen-md) {
        padding: 10px 30px 0 30px;
      }
    }
    & .driver_resource {
      @media screen and (min-width: $screen-md) {
        padding: 10px 10px 10px 25px;
      }
      @media screen and (max-width: $screen-md) {
        padding: 10px 30px;
      }

      & .resource_header {
        font-weight: 600;
      }
      & .resource_details {
        color: $gray;
      }
    }
  }
}

.driver_my_earnings_container {
  h3 {
    font-weight: 400;
  }
  & .driver_my_earnings_name_and_print {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .my_earnings_print_button {
      display: contents;
      @media print {
        display: none;
      }
      & .my_earnings_print_icon {
        color: gray;
        height: 20px;
        align-self: flex-end;
      }
    }
  }
  & .driver_my_earnings_detail_container {
    & .pagination {
      @media print {
        display: none;
      }
    }
    & .driver_my_earnings_table {
      margin-bottom: 25px;
      width: 100%;
      & tr {
        vertical-align: bottom;
        & .earnings_transfer_with_border {
          border-bottom: 1px solid lightgray;
        }
        & .earnings_payout_with_border {
          border-bottom: 1px solid lightgray;
        }
      }
      & td {
        padding: 10px 0;
      }
      & .earnings_payout_row {
        & td {
          @apply bg-blue-100 py-10 relative align-middle;
          text-align: start;

          &::before {
            @apply bg-white w-full h-6 top-0 left-0 absolute block;
            content: '';
          }
          &::after {
            @apply bg-white w-full h-6 bottom-0 left-0 absolute block;
            content: '';
          }
        }
      }
      & .earnings_transfer_row {
        & .earnings_transfer_amount {
          padding-right: 10px;
          text-align: start;
        }
        & .earnings_transfer_date {
          text-align: center;
          min-width: 175px;

          &.text-left {
            text-align: start;
          }
        }
        & .earnings_transfer_details {
          & .earnings_transfer_details_printable {
            display: none;
            @media print {
              display: block;
            }
          }
          & .earnings_transfer_details_not_printable {
            @media print {
              display: none;
            }
          }
          text-align: end;

          &.text-left {
            text-align: start;
          }
        }
        & .earnings_transfer_engagement {
          text-align: left;
        }
      }
    }
    @media screen and (min-width: $screen-md) {
      width: 75%;
    }
  }
}

.end_of_earnings {
  margin: 10px 0;
  display: flex;

  & .end_of_earnings_text {
    margin: 0 10px;
    text-align: center;
    color: $gray-light;
  }
  & .end_of_earnings_line {
    flex: 1;
    border-top: 1px solid $gray-lightest;
    margin: 10px 0;
  }
}

.mask-button-container {
  display: flex;
  justify-content: center;
}

.mask-button {
  color: black !important;
  &:hover {
    color: $brand-orange !important;
  }
}
.mask-button-disabled {
  color: gray !important;
}

.legacy-pricing-info-icon {
  padding: 5px 0px;
  font-size: small;
  display: flex;
  justify-content: flex-end;
  .legacy-pricing-info-tooltip {
    position: relative;
    display: inline-block;
    .legacy-pricing-info-tooltiptext {
      visibility: hidden;
      width: 150px;
      background-color: $gray-lighter;
      color: black;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      line-height: 15px;
      position: absolute;
      z-index: 1;
      top: -5px;
      left: 150%;
      font-family: $font-family-base;
    }
  }
  .legacy-pricing-info-tooltip:hover .legacy-pricing-info-tooltiptext {
    visibility: visible;
  }
}
.eta-tooltip-icon {
  color: black;
  text-decoration: none;
  font-size: 9px;
  padding-left: 5px;
}
.eta-tooltip-container {
  background-color: white;
  color: black;
}
.eta-tooltip {
  color: black;
  background-color: rgb(248, 248, 248);
  border: 1px solid black;
}
.hr-danger {
  border-top: 1px solid $brand-danger;
}
.flex-driver-activation-button {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $screen-md) {
    max-width: 25%;
  }
}
.flex-driver-activation-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
}
.flex-driver-activation-main-text {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.flex-driver-activation-sub-text {
  font-size: 0.7em;
  padding-top: 5px;
}
.red-border-btn {
  border-color: $brand-red;
  &.text-danger:hover {
    color: $brand-red;
  }
  &.disabled {
    color: $gray-lightest;
    border-color: $gray-lightest;
  }
}
.green-border-btn {
  border-color: $brand-green;
  margin-right: 10px;
}
.missing-orders-text {
  color: #a94442;
  font-size: 0.8em;
  padding: 10px;
}
.missing-order-description {
  font-size: 0.9em;
  padding: 10px;
}
.missing-orders-relief-text {
  color: #27592d;
  font-size: 0.9em;
  padding: 10px;
}
.align-text-center {
  text-align: center;
}
.alert-info-no-cancel {
  background-color: $state-info-no-cancel-bg;
  color: $state-info-no-cancel-text;

  z-index: $zindex-above-navbar-alert;
  position: fixed;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  top: 0;
  right: 0;

  font-size: 14px;
  font-weight: bold;

  padding: 2px 8px;
  -webkit-box-shadow: 0px 0px 12px rgba(23, 43, 77, .04), 2px 3px 12px rgba(23, 43, 77, .08);
  box-shadow: 0px 0px 12px rgba(23, 43, 77, .04), 2px 3px 12px rgba(23, 43, 77, .08);
}
.modal_container {
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: gray;
  position: fixed;
  top: 0;
  left: 0;
}
.modal_div {
  display: none;
  background-color: white;
  position: fixed;
  height: 50%;
  width: 80%;
  top: 25%;
  left: 10%;
}
.modal_div_x_close {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 10px;
  align-self: flex-end;

  & button {
    height: 25px;
    width: 50px;
    background-color: inherit;
    border: none;
  }
}
.pricing_zone_config_details_container {
  height: 100%;
  width: 100%;

  overflow: scroll;

  &.pricing_zone_large_screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1250px) {
      display: none;
    }
  }

  &.pricing_zone_small_screen {
    display: none;

    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.toggle_price_config_comparision_button_container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.location_pricing_config_service_level_sub_table_singlet {
  min-height: 100%;
  & tr > td {
    padding: 2px;
    font-size: 0.75em;
  }
}

.location_pricing_config_service_level_sub_table_small_screen_body {
  text-align: center;
  padding: 5px;
  & tr > td {
    &.costs_header {
      font-size: 0.8em;
      padding: 2px;
    }
  }
  @media (max-width: $screen-sm) {
    & tr > td {
      &.costs_header {
        font-size: 0.58em;
        padding: 2px;
      }
    }
  }
}
.location_pricing_config_service_level_sub_table {
  width: 95%;
}
.location_pricing_config_service_level_sub_table_small_screen {
  width: 80%;
}
.pricing_config_action_button {
  font-size: 0.8em;
  padding: 5px;
}
.pricing_zone_base_config_comp_text {
  color: red;
  font-size: 0.8vw;
}
.pricing_zone_base_config_comp_text_small_screen {
  color: red;
  @media (max-width: $screen-sm) {
    & tr > td {
      padding: 2px;
    }
  }
}
.admin_market_line_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: dotted;
  border-bottom-color: #d85f36;
  border-bottom-width: 0.5px;
  padding: 10px;
  &:hover {
    background-color: $gray-lighter;
  }
}
.js-organization-referral-company-find-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.org-payment-icon {
  width: 75px;
  height: 75px;
  margin-right: 20px;
  flex: 0 0 auto;

  > img {
    width: 100%;
    height: auto;
  }
}

.setup-payment-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.setup-payment-card-container {
  min-width: 350px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(6, 123, 194);
  border-radius: 4px;
}

.setup-payment-header {
  font-size: 16px;
  font-weight: 500;
}

.incomplete-payment-setup-alert {
  box-shadow: 1px 4px 12px -4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.incomplete-payment-side-bar {
  width: 14px;
  height: 150px;
  background: rgb(232, 54, 32);
  border-radius: 2px 0px 0px 2px;
  margin-right: 30px;
}

.incomplete-payment-header {
  color: rgb(232, 54, 32);
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 32px;
}

.incomplete-payment-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.incomplete-payment-icon {
  font-size: 24px;
  color: rgb(232, 54, 32);
  margin-right: 5px;
}

.incomplete-payment-text-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.incomplete-payment-button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 15px;
}

.role-membership-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.credit-app-completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

[data-toggle='collapse'] {
  .collapse-label-collapsed {
    display: none;
  }

  .collapse-label-expanded {
    display: block;
  }

  &.collapsed {
    .collapse-label-collapsed {
      display: block;
    }

    .collapse-label-expanded {
      display: none;
    }
  }
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}

// This adds padding to avoid conflicting with the Intercom badge at the bottom right of the screen
.pr-avoid-intercom {
  @media (max-width: 1275px) {
    padding-right: 100px !important;
  }
}

.inline-visibility-hidden {
  visibility: hidden;
}
.internal-only {
  background-color: $gray-lighter;
}
.darth-grey-line {
  border-top: 1px $panel-default-border solid;
}
.break-words {
  word-break: break-word;
}
.xl-icon-flag {
  font-size: x-large;
}
.lg-icon-flag {
  font-size: large;
}

.marketplace-photo {
  width: calc(50% - 32px) ;
  display: inline-block;
  margin-bottom: 16px;
  margin-right: 16px;
  max-width: 240px;
}

.mp-dashboard-list {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'info'
    'cta'
    'credits'
    'insights'
    'main';

  @media (min-width: theme('screens.sm')) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'info    info'
      'cta     cta'
      'credits insights'
      'main    main';
  }

  @media (min-width: theme('screens.md')) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'info info    info'
      'cta  credits insights'
      'main main    main';
  }

  @media (min-width: theme('screens.lg')) {
    grid-template-columns: 330px 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'info     main'
      'cta      main'
      'credits  main'
      'insights main';
  }
}

.mp-dashboard-list__info {
  grid-area: info;
}
.mp-dashboard-list__cta {
  grid-area: cta;
}
.mp-dashboard-list__credits {
  grid-area: credits;
}
.mp-dashboard-list__insights {
  grid-area: insights;
}
.mp-dashboard-list__main {
  grid-area: main;
  min-width: 0;
}

.mp-dashboard-list--single-col {
  display: block;
  .mp-dashboard-list__cta {
    display: none;
  }
  .mp-dashboard-list__credits {
    display: none;
  }
  .mp-dashboard-list__insights {
    display: none;
  }
}

.customer-edit {
  @include clearfix;

  &.editable {
    @apply border-2 border-gray-200 p-4 rounded;
  }
}

.customer-edit__message {
  @include clearfix;
  @apply hidden;

  &.editable {
    @apply bg-gray-50 p-6 mb-4 rounded block;
  }
}

.bg-marketing-quick-estimator-image {
  background-image: url('~assets/images/marketing-quick-estimator-main-bg.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;

  @apply hidden;
  // position: absolute;
  // width: 345px;
  // height: 782px;
  // left: 0;
}

.bg-marketing-quick-estimator {
  background: radial-gradient(62% 248.71% at 38% 41.55%, #003A5D 66.75%, #0072ED 100%);
}

.bg-marketing-quick-estimator-light {
  background: #F2F8FE;
}

.bg-button-marketing-quick-estimator-light {
  background: #0072ED;
}

.text-color-marketing-quick-estimator-accent {
  color: #FF8300;
}

.text-color-marketing-quick-estimator {
  color: #003A5D;
}

.text-color-marketing-quick-estimator-light {
  color: #0072ED;
}

.button-secondary-marketing-quick-estimator {
  background: rgba(0, 114, 237, 0.1);
  color:rgba(0, 114, 237, 1);
}

.smooth-scroll-animation {
  scroll-behavior: smooth;
}

.radius-marketing-quick-estimator {
  border-radius: 3px;
}

.margin-40-marketing-quick-estimator-elements-top {
  margin-top: 40px;
}

@media (min-width: 1024px) { /* Tailwind’s `lg` breakpoint */
  .lg\:radius-marketing-quick-estimator {
    @apply radius-marketing-quick-estimator;
  }
}

.rule-fields .user-defined-value[type='checkbox'] {
  width: 30px;
  height: 30px;
}

.promotions-form .absolute-errors .help-block {
  position: absolute;
  color: $brand-danger !important;
}

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.visible-scrollbar::-webkit-scrollbar:vertical {
  width: 11px;
}

.visible-scrollbar::-webkit-scrollbar:horizontal {
  height: 11px;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.visible-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
